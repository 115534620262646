<template>
  <div id="share-home">
    <!-- public content -->
    <template v-if="shareUserLoaded">
        <div class="flex w-full">
            <div class="flex">
                <user-avatar user-avatar v-if="shareUserLoaded" :user="shareUser" size="60px"></user-avatar>
            </div>
            <div class="mx-8 my-4">
                <h4>{{$t('invitationFrom')}} {{shareInfos.first_name | capitalize}} {{shareInfos.last_name | capitalize}} #{{share_hash}}</h4>
                <p>{{$t('Portal')}} {{ decodeURIComponent(shareInfos.portal_nicename) }}</p>
            </div>
        </div>
        <!-- erreurs -->
        <div v-if="!isShowable" class="m-8">
            <vs-alert color="warning" v-if="!expireDateIsOk">{{$t('expireDatePast')}} ({{this.shareInfos.expire_date | middleDate}})</vs-alert>
            <vs-alert color="warning" v-else-if="!useLimitIsOk">{{$t('numberOfUsersExceeded')}}</vs-alert>
            <vs-alert color="warning" v-else-if="passwordProtected">{{$t('passwordProtectedShare')}}</vs-alert>
        </div>
        <!-- password input -->
        <div v-if="passwordProtected" class="m-8">
            <template v-if="!passwordIsOk">
                <vx-input-group class="mb-base">
                    <vs-input 
                    class="w-full"
                    v-model="passwordInput" 
                    @input="passwordChecked = false"
                    @blur="checkPassword()"
                    :danger="!passwordIsOk && passwordInput!=='' && passwordChecked"
                    :danger-text="$t('wrongPassword')"
                    :placeholder="$t('typePasswordHere')"
                    val-icon-danger="icon-x"
                    val-icon-pack="feather"
                    ></vs-input>
                    <template slot="append">
                        <div class="append-text btn-addon">
                        <vs-button color="primary" @click="passwordChecked = true">{{$t('Ok')}}</vs-button>
                        </div>
                    </template>
                </vx-input-group>
            </template>
        </div>

        <!-- limitations levées -->
        <div v-if="isShowable" class="m-8">
            <!-- demande de connexion si nécessaire -->
            <div v-if="!loggedIn">
              <p>{{$t('shareUseNeedsConnection')}}</p>
              <!-- <vs-alert color="danger" class="mt-5" icon-pack="feather" icon="icon-rotate-cw">{{$t('migrationLoginMessage')}}</vs-alert> -->
              <vs-button class="my-4" color="primary" @click="login()">{{$t('loginOrRegister')}}</vs-button>
            </div>

            <!-- contenu si connecté -->
            <div class="my-6 md:mx-6" v-if="loggedIn">
              <h5 class="my-6">{{$t('rightsWillBeAdded')}} :</h5>
                <vx-card v-for="project in shareCaps" :key="project.project_slug"
                :title="decodeURIComponent(project.project_nicename)"
                class="my-6">
                <div>
                  <div v-for="dateCap in projectCapsDates" :key="project.project_slug+'_'+dateCap" class="mb-2">
                    <p v-if="typeof project[dateCap] !== 'undefined'">{{$t(dateCap)}} : {{project[dateCap] | middleDate}}</p>
                  </div>
                </div>
                <div class="md:flex">
                  <div class="all:w-full md:w-1/3">
                    <div v-for="basicCap in projectCapsBasicCheckboxes" :key="project.project_slug+'_'+basicCap" class="mt-1 flex">
                      <div v-if="basicCap !=='' && project[basicCap]">
                        <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" class="ml-1" />
                        <label class="ml-5">{{$t(basicCap)}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="all:w-full md:w-1/3">
                    <div v-for="warningCap in projectCapsWarningCheckboxes" :key="project.project_slug+'_'+warningCap" class="mt-1 flex">
                      <div v-if="warningCap !=='' && project[warningCap]">
                        <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" class="ml-1" />
                        <label class="ml-5">{{$t(warningCap)}}</label>
                      </div>
                    </div>
                  </div>
                  <div class="all:w-full md:w-1/3">
                    <div v-for="adminCap in projectCapsAdminCheckboxes" :key="project.project_slug+'_'+adminCap" class="mt-1 flex">
                      <div v-if="adminCap !=='' && project[adminCap]">
                        <feather-icon icon="CheckIcon" svgClasses="h-4 w-4" class="ml-1" />
                        <label class="ml-5">{{$t(adminCap)}}</label>
                      </div>
                    </div>
                  </div>
                </div>
                </vx-card>
                <div class="w-full md:flex">
                  <div class="ml-auto">
                    <vs-alert v-if="currentUserIsShareCreator" color="danger" class="mb-2">{{$t('cannotUseYourOwnShare')}}</vs-alert>
                    <vs-button @click="acceptShare" v-if="isUsable" :disabled="!isUsable" size="large" icon-pack="feather" icon="icon-check-square" class="ml-auto">{{$t('acceptInvitation')}}</vs-button>
                  </div>
                </div>
                
            </div>
        </div>
    </template>

  </div>
</template>
<script>
import axios from '@/axios.js'
import moment from 'moment' // for date comparaison
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import logActivity from '@/logActivity.js'

export default {
  components: {
    CryptoJS, 
    sha1, 
    logActivity
  },
  data () {
    return {
      // ROYBON / Share informations
      share_hash: this.$route.params.share_hash,
      shareInfos: {},
      shareInfosLoaded: false,
      shareUser: {},
      shareCaps: {},
      shareUserLoaded: false,
      passwordMatches: false,
      passwordChecked: false,
      passwordInput: '',

      // ROYBON / Teste si user a déjà accès
      alreadyHasAccess: false,

      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getShare: 'getShare',
      setUserCapsFromShare: 'setUserCapsFromShare',

      // ROYBON / Liste des capacités
      projectCapsBasicCheckboxes: [
        'can_project_model_see',
        'can_project_tags_see',
        'can_project_tools_use',
        'can_project_users_see'
      ],
      projectCapsWarningCheckboxes: [
        'can_project_tags_add',
        'can_project_shares_send'
      ],
      projectCapsAdminCheckboxes: [
        'can_project_customize',
        'can_project_tags_manage',
        'can_project_users_manage',
        'can_project_layers_manage'
      ],
      projectCapsDates: [
        'can_project_access_start_date',
        'can_project_access_end_date'
      ]

    }
  },
  methods: {    
    // ROYBON / Obtient les infos sur le partage
    getShareInfos () {
      const params = `share_hash=${this.share_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getShare}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            this.shareInfos = response.data[0]
            this.shareCaps = JSON.parse(decodeURIComponent(response.data[0].caps))
          }
          this.shareInfosLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Contrôle le password
    checkPassword () {
      this.passwordMatches = (this.shareInfos.password === encodeURIComponent(sha1(this.passwordInput)))
      this.passwordChecked = true
    },
    // ROYBON / Login
    login () {
      this.$auth.login({ target: `/s/${this.$route.params.share_hash}` })
    },

    // ROYBON / Acceptation du partage
    acceptShare () {
      //const params = `user_id=${this.$store.state.AppActiveUser.user_id}&share=${this.share_hash}`
      const params = new FormData()
      params.set('user_id', `${this.$store.state.AppActiveUser.user_id}`)
      params.set('share', `${this.share_hash}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.setUserCapsFromShare}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // log activity
            logActivity.add('share_used', this.share_hash)
            const redirect = `/${this.shareInfos.portal_slug}`
            this.$router.push(redirect).catch(() => {})
            // success
            this.$vs.notify({
              color:'success',
              title:this.$t('success'),
              text:this.$t('capsUpdated')
            })
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              title:this.$t('error'),
              text:this.$t('capsNotUpdated')
            })
          }
        })
        .catch((error)   => { console.log(error) })
    }
  },
 
  computed: {
    isUsable () {
      return this.isShowable && !this.currentUserIsShareCreator
    },
    isShowable () {
      return this.shareInfosLoaded && this.passwordIsOk && this.useLimitIsOk && this.expireDateIsOk
    },
    passwordProtected () {
      return this.shareInfos.password !== null
    },
    passwordIsOk () {
      return !this.passwordProtected || (this.passwordProtected && this.passwordMatches)
    },
    useLimitProtected () {
      return this.shareInfos.use_limit !== null
    },
    numberOfUsersExceeded () {
      return this.useLimitProtected && this.shareInfos.users >= this.shareInfos.use_limit
    }, 
    useLimitIsOk () {
      return !this.useLimitProtected || (this.useLimitProtected && !this.numberOfUsersExceeded)
    },
    expireDateProtected () {
      return this.shareInfos.expire_date !== null
    }, 
    expireDatePast () {
      return moment().subtract(1, 'd').isSameOrAfter(this.shareInfos.expire_date)
    }, 
    expireDateIsOk () {
      return !this.expireDateProtected || (this.expireDateProtected && !this.expireDatePast)
    },
    loggedIn () {
      return this.$store.state.AppActiveUser.user_id !== null && parseInt(this.$store.state.AppActiveUser.user_id) !== '0'
    },
    currentUserIsShareCreator () {
      return parseInt(this.$store.state.AppActiveUser.user_id) === parseInt(this.shareUser.user_id)
    }
  },

  beforeMount () {
  },

  created () {
  },
  mounted () {
    this.getShareInfos()
    if (!this.loggedIn) {
      sessionStorage.setItem('loginRedirect', `/s/${this.share_hash}`)
      console.log('loginRedirect cookie created')
    }
    const previousPassword = sessionStorage.getItem('sharePassword')
    if (previousPassword !== '') this.passwordInput = previousPassword
    sessionStorage.removeItem('sharePassword')
  },

  watch: {
    shareInfos: function () { 
      this.shareUser.first_name = this.shareInfos.first_name
      this.shareUser.last_name = this.shareInfos.last_name
      this.shareUser.user_id = this.shareInfos.user_id
      this.shareUserLoaded = true
    }, 
    passwordIsOk: function () {
      if (this.passwordIsOk) {
        sessionStorage.setItem('sharePassword', this.passwordInput)
      }
    }
  }
}

</script>
<style lang="scss">
</style>
